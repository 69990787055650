<template>
    <div class="offer_pop">
        <van-popup v-model="showofferpop" @close="close" :lock-scroll="true" :closeOnClickOverlay='false'>
            <div class="popup_frame">
                <img :class="[lang === 2 ? 'theme_img_er' : 'theme_img']" src="../../assets/imgs/light_bg.png" alt="">
                <div class="content">
                    <div class="title">{{ $t('m.openrechargebonusdays') }}</div>
                    <div class="text">{{ $t('m.rechargedesctext') }}</div>
                    <div class="switch_btn">
                        <div class="ok_btn" @click="close">{{ $t('m.ok') }}</div>
                    </div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
export default ({
    props: {
        showofferpop: {
            type: Boolean,
            default: false
        },
        optType: {
            type: Number,
            default: 1
        },
        lang: {
            type: Number,
            default: 1
        }
    },
    data() {
        return {
        }
    },
    created() {
        ////console.log('弹窗类型...........', this.optType, 'lang..............', this.lang)
    },
    methods: {
        close() {
            this.$emit('closePop')
        },
        confirmFun() {
            this.$emit('confirmFn')
        }
    }
})
</script>

<style lang="scss" scoped>
@import "../../assets/css/common.scss";

.offer_pop {
    min-height: 359px;
    height: auto;    
    position: relative;
    .van-popup {
        overflow-y: inherit !important;   
        background-color: transparent;
    }
    .popup_frame {
        padding: 2.5px;
        width: 80vw;
        border-radius: 4vw;
        position: relative;
        // border: 1px solid blue;

        img {
            width: 80vw;
            position: absolute;
            top: -6rem;
            left: 0;
            right: 0;
            margin: auto;
            // border: 1px solid red;
        }
        // .theme_img, .theme_img_er {
        //     box-sizing: border-box;
        //     width: 375px;
        //     height: 359px;
        //     position: absolute;
        //     top: -55%;
        //     z-index: 5;
        // }

        .content {
            background: linear-gradient(180deg, #FCEDAB 52%, #FFFFFF 100%);
            width: 80vw;
            border-radius: 3.8vw;
            padding: 18vw 0 8vw 0;
            margin-top: 18vw;

            span {
                display: block;
            }

            .title {
                font-size: 18px;
                font-weight: 600;
                color: #FF5C35;
                line-height: 21px;
                margin-bottom: 15px;
            }

            .text {
                font-size: 15px;
                font-weight: 400;
                color: #333333;
                line-height: 18px;
                margin: 0 16px;

                .bon {
                    font-weight: 500;
                    margin-top: 14px;
                }
            }

            .switch_btn {
                @include flex-around;
                padding-bottom: 3px;

                .ok_btn {
                    width: 250px;
                    height: 48px;
                    line-height: 48px;
                    background: linear-gradient(90deg, #FF5A34 0%, #FF7533 100%);
                    border-radius: 52px 52px 52px 52px;
                    opacity: 1;
                    font-size: 16px;
                    font-weight: 600;
                    margin-top: 16px;
                    color: #FFFFFF;
                }
            }

            .btn {
                height: auto;
                padding: 91px 17px 24px 17px;
                box-sizing: border-box;
                width: 100%;
                background: linear-gradient(90deg, #FF5A34 0%, #FF7533 100%);
                border-radius: 52px 52px 52px 52px;
                opacity: 1;
                position: relative;
                @include flex-around;
            }

            .cancel {
                color: #FFFFFF;
                font-size: 17px;
                margin-top: 3vw;
            }
        }
    }
}    

// .offer_pop {
//     min-height: 359px;
//     height: auto;
//     .van-popup {
//         background-color: transparent !important;
//         margin: auto;
//         overflow-y: inherit !important;   
//         width: 80%;     
//         // border: 1px solid #33ff41;

//         .theme_img, .theme_img_er {
//             box-sizing: border-box;
//             width: 375px;
//             height: 359px;
//             position: absolute;
//             top: -55%;
//             z-index: 5;
//         }
//         .theme_img {
//             top: -30vh;
//             left: 50%;
//             right: 0;
//             transform: translateX(-50%);
//         }
//         .theme_img_er {
//             top: -30vh;
//             left: 0;
//             right: 50%;
//             transform: translateX(50%);
//         }
//         .popup_frame {
//             // min-height: 344px;
//             height: auto;
//             padding: 91px 17px 24px 17px;
//             box-sizing: border-box;
//             width: 100%;
//             background: linear-gradient(180deg, #FCEDAB 52%, #FFFFFF 100%);
//             border-radius: 16px 16px 16px 16px;
//             opacity: 1;
//             position: relative;
//             // border: 2px solid blue;

//             .white {
//                 color: #FFFFFF;
//             }
//             .content {
//                 .title {
//                     position: relative;
//                     z-index: 5;
//                     font-size: 18px;
//                     font-weight: 600;
//                     color: #FF5C35;
//                     line-height: 21px;
//                 }
//                 .content {
//                     font-size: 15px;
//                     font-weight: 400;
//                     color: #333333;
//                     line-height: 18px;
//                     margin: 16px 0 22px 0;
//                 }
//                 .switch_btn {
//                     @include flex-around;
//                     padding-bottom: 3px;

//                     .ok_btn {
//                         width: 250px;
//                         height: 48px;
//                         line-height: 48px;
//                         background: linear-gradient(90deg, #FF5A34 0%, #FF7533 100%);
//                         border-radius: 52px 52px 52px 52px;
//                         opacity: 1;
//                         font-size: 16px;
//                         font-weight: 600;
//                         color: #FFFFFF;
//                     }

//                     // .confirm,.cancel{
//                     //     width: 148px;
//                     //     height: 45px;
//                     //     @include flex-cen;
//                     //     font-size: 14px;
//                     //     font-weight: bold;
//                     //     color: #FFFFFF;
//                     //     text-shadow: 0px 1px 0px #000000;
//                     // }
//                     // .confirm {
//                     //     background: url("src/assets/imgs/earth/check_blue.png") no-repeat;
//                     //     background-size: 100% 100%;
//                     // }
//                     // .cancel {
//                     //     background: url("src/assets/imgs/earth/check_red.png") no-repeat;
//                     //     background-size: 100% 100%;
//                     // }
//                 }
//             }
//         }
//     }
// }
</style>
